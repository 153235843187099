import chroma from 'chroma-js'
import parse from 'html-react-parser'
import moment from 'moment'
import { Helmet } from 'react-helmet-async'
import { isValidEmail } from 'src/clinic/views/onboardng/clinicBrand/components/utils'
import { getChangedNumberValueFromInput } from 'src/mentor/helpers/utilityFunctions'
import * as XLSX from 'xlsx'
import { APP_CONSTANTS, STRING_ID_TYPES } from '../constants'

const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const nameRegExp = /^[a-zA-Z\s]+$/
const onlyNumbersRegExp = /^[0-9]+$/

const FAKE_EVENT_OBJECT = { target: { id: null, value: null } }

export function getCSmartTableColumnData({ key, label, filter, sorter, _style }) {
  return {
    key,
    label,
    filter,
    sorter,
    _style,
  }
}

export const openInNewTab = (url) => {
  window.open(url, '_blank')
}

export function formatNumberWithMinTwoDigits(num) {
  return num?.toString()?.padStart(2, '0')
}

export function validateEmail(emailString) {
  if (emailRegExp.test(emailString)) {
    return true
  }
  return false
}

export function validateName(nameString) {
  if (nameRegExp.test(nameString)) {
    return true
  }
  return false
}

export function validateOnlyNumbers(nameString) {
  if (onlyNumbersRegExp.test(nameString)) {
    return true
  }
  return false
}

export function formatDateForPosts(inputDate) {
  const today = moment()
  const date = moment(inputDate, 'YYYY-MM-DD HH:mm:ss')

  if (date.isSame(today, 'day')) {
    return 'Today'
  } else if (date.isSame(today, 'year')) {
    return date.format('MMM D')
  } else {
    return date.format('MMM D, YYYY')
  }
}

export function countWordsInHTML(htmlString) {
  // Remove HTML tags and extract text content
  const textContent = htmlString.replace(/<[^>]*>/g, '')
  // Split the text into words using whitespace as a delimiter
  const words = textContent.split(/\s+/)
  // Count the number of words
  return words.length
}

export function calculateReadTimeFromWords(words) {
  const wpm = 130
  const toReturn = Math.floor(parseInt(words) / wpm)
  if (toReturn > 0) {
    return toReturn
  } else {
    return 1
  }
}

export function generateUidForAgora() {
  const minUserID = 1
  const maxUserID = Math.pow(2, 32) - 1

  // Generate a random integer within the specified range
  const uid = Math.floor(Math.random() * (maxUserID - minUserID + 1)) + minUserID

  return uid // Convert the integer to a string
}

export const SEOComponent = ({ title, description, image, keywordsArr }) => {
  let keyWordsArray = [
    ...(Array.isArray(keywordsArr) ? keywordsArr : []),
    ...(APP_CONSTANTS.DEFAULT_KEYWORDS || []),
  ]
  const uniqueKeywordsSet = new Set(keyWordsArray)

  const titleString = title || APP_CONSTANTS.DEFAULT_TITLE
  const keywordsString = Array.from(uniqueKeywordsSet).join(', ')
  const descriptionString = description || APP_CONSTANTS.DEFAULT_DESCRIPTION
  const imageLink = image || APP_CONSTANTS.APP_LOGO_LINK

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={descriptionString} />
      <meta name="keywords" content={keywordsString} />

      {/* Open Graph Protocol (OGP) meta tags */}
      {/* <meta property="og:type" content={type} /> */}
      <meta property="og:title" content={titleString} />
      <meta property="og:description" content={descriptionString} />
      <meta property="og:image" content={imageLink} />

      {/* Twitter Card meta tags */}
      {/* <meta name="twitter:creator" content={name} /> */}
      {/* <meta name="twitter:card" content={type} /> */}
      <meta name="twitter:title" content={titleString} />
      <meta name="twitter:description" content={descriptionString} />
      <meta name="twitter:image" content={imageLink} />
    </Helmet>
  )
}

export function calculateMinWidthAndHeight(decimalAspectRatio) {
  if (decimalAspectRatio <= 0) {
    throw new Error('Aspect ratio must be a positive number.')
  }

  // Calculate the minimum width and height
  const gcd = (a, b) => (b < 1e-5 ? a : gcd(b, a % b))
  const denominator = 1 / decimalAspectRatio
  const minWidth = 1 / gcd(1, denominator)
  const minHeight = denominator / gcd(1, denominator)

  return { minWidth, minHeight }
}

export function checkAndGetTargetValue(e) {
  switch (e.target.type) {
    case 'checkbox':
      return e.target.checked
    case 'number':
      return e.target.value === '' ? null : Number(e.target.value)

    default:
      return e.target.value
  }
}

export function getFCMToken() {
  return localStorage.getItem('FCMToken')
}

export function removePlusFromBeginning(inputString) {
  if (inputString) {
    if (inputString?.startsWith('+')) {
      return inputString?.substring(1)
    } else {
      return inputString
    }
  }
}

export function identifyUUIDOrSlugType(str) {
  const uuidRegex = /^[a-zA-Z0-9]{3}-[0-9a-fA-F]{32}$/
  if (uuidRegex.test(str)) {
    return STRING_ID_TYPES.uuid
  } else {
    return STRING_ID_TYPES.slug
  }
}

export function separateItemsInArray(onBasisOfField, array) {
  const toReturn = {}
  for (let i = 0; i < array.length; i++) {
    // checking and adding categoryId to toReturn
    if (!toReturn.hasOwnProperty(array[i][onBasisOfField])) {
      toReturn[array[i][onBasisOfField]] = []
    }
    toReturn[array[i][onBasisOfField]] = [...toReturn[array[i][onBasisOfField]], array[i]]
  }
  return toReturn
}

export const defaultConfirmationModalProps = {
  visibility: false,
  visibilitySetter: null,
  action: '',
  body: '',
  buttonColor: 'success',
  onSubmitFunctions: [],
}

export function generateHexWithOpacity(opacity = 1) {
  // Generate a random color
  const randomColor = chroma.random()

  // Set the desired opacity
  const colorWithOpacity = chroma(randomColor).alpha(opacity)

  // Convert to hex and return
  return colorWithOpacity.hex()
}

function isColorUnique(color, existingColors) {
  // Check if the color is not similar to any existing color
  return !existingColors.some((existingColor) => chroma.deltaE(existingColor, color) < 20) // Adjust the threshold as needed
}

/**
 * Generate a hex color code not similar to existing colors.
 * @param {number} opacity - Opacity value between 0 and 1 (default is 1).
 * @param {string[]} existingColors - Array of existing hex color codes.
 * @returns {string} Hex color code.
 */
export function generateUniqueHexWithOpacity(opacity = 1, existingColors = []) {
  let generatedColor

  // Retry until a color is found that is not similar to any in the existingColors array
  while (true) {
    generatedColor = generateHexWithOpacity(opacity)

    // Check if the generated color is not similar to any existing color
    const isUnique = isColorUnique(generatedColor, existingColors)

    if (isUnique) {
      break
    }
  }

  return generatedColor
}

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export function sortFn(obj1, obj2) {
  if (obj1 === obj2) {
    return 0
  } else {
    return obj1 > obj2 ? 1 : -1
  }
}

export function checkIfStringIsValid(str) {
  if (typeof str !== 'string') {
    return false
  }
  return str.length > 0
}

export function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value)
}

export function isValidInteger(value) {
  return isValidNumber(value) && Number.isInteger(value)
}

export function getValidNumberFromStringNumber(str) {
  const number = Number(str)
  return isValidInteger(number) ? number : null
}

export function getWhatsAppShareLink(text) {
  const isMobileOrTablet = /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)

  if (isMobileOrTablet) {
    return `https://${isMobileOrTablet ? 'api' : 'web'}.whatsapp.com/send?text=${text}`
  } else {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const isWhatsappInstalled = userAgent.includes('whatsapp')
    return `${isWhatsappInstalled ? 'whatsapp://?' : 'https://web.whatsapp.com/'}send?text=${text}`
  }
}

export function checkIfStringIsASlugOrId(str) {}

export function addToGoogleCalendarLink(
  utcStartDateTime,
  utcEndDateTime,
  name,
  description,
  moment,
) {
  const startDateString = moment?.utc(utcStartDateTime)?.format('YYYYMMDDTHHmmss[Z]')
  const endDateString = moment?.utc(utcEndDateTime)?.format('YYYYMMDDTHHmmss[Z]')
  const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${name}&details=${description}&dates=${startDateString}/${endDateString}`
  return googleCalendarLink
}

export function genericHandleChange(event, setter) {
  if (event.target.id)
    setter((prev) => ({
      ...prev,
      [event.target.id]: checkAndGetTargetValue(event),
    }))
  else {
    setter(checkAndGetTargetValue(event))
  }
}

export function getCorrectTargetValueForEvent(e, overrideNumberTransform = false) {
  switch (e.target.type) {
    case 'checkbox':
      return e.target.checked
    case 'number':
      if (overrideNumberTransform) {
        return e.target.value
      }
      return getChangedNumberValueFromInput(e.target.value)

    default:
      return e.target.value
  }
}

export function giveGenericHandleChangedData(
  eventObjOrPrevFuncOrValueToSet,
  prev,
  overrideNumberTransform,
  objectIsNotEventObject = false,
) {
  if (typeof eventObjOrPrevFuncOrValueToSet === 'function') {
    return eventObjOrPrevFuncOrValueToSet(prev)
  } else {
    if (objectIsNotEventObject) {
      return eventObjOrPrevFuncOrValueToSet
    } else {
      if (eventObjOrPrevFuncOrValueToSet.target.id)
        return {
          ...prev,
          [eventObjOrPrevFuncOrValueToSet.target.id]: getCorrectTargetValueForEvent(
            eventObjOrPrevFuncOrValueToSet,
            overrideNumberTransform,
          ),
        }
      else {
        return getCorrectTargetValueForEvent(eventObjOrPrevFuncOrValueToSet)
      }
    }
  }
}

export function handleEventTargetChange(prev, event) {
  return {
    ...prev,
    [event.target.id]: getCorrectTargetValueForEvent(event),
  }
}

export function genericChangeNestedFields(value, outerId, setter) {
  setter((prev) => ({
    ...prev,
    [outerId]: typeof value === 'function' ? value(prev?.[outerId]) : value,
  }))
}

export function genericHandleNestedFields(event, outerId, setState) {
  if (typeof event === 'function') {
    genericChangeNestedFields((prev) => event(prev), outerId, setState)
  } else {
    genericChangeNestedFields((prev) => handleEventTargetChange(prev, event), outerId, setState)
  }
}

export function genericHandleNestedNestedFields(event, outerId, outerOuterId, setter) {
  setter((prev) => ({
    ...prev,
    [outerOuterId]: {
      ...(prev?.[outerOuterId] || {}),
      [outerId]: {
        ...(prev?.[outerOuterId]?.[outerId] || {}),
        [event.target.id]: giveGenericHandleChangedData(event, prev?.[outerOuterId]?.[outerId]),
      },
    },
  }))
}

export function titleCaseWithExceptionWords(str, exceptions = []) {
  // Split the string into words
  const words = str.split(' ')

  // Convert each word to title case except the exceptions
  const titleCaseWords = words.map((word) => {
    // Check if the word is in exceptions
    if (exceptions.includes(word.toLowerCase())) {
      return word // Leave the word as is
    } else {
      // Convert the word to title case
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    }
  })

  // Join the title case words back into a string
  return titleCaseWords.join(' ')
}

export function getParseDataFromHtmlString(htmlString, otherParams = { format: true }) {
  const { format } = otherParams

  let formattedString = htmlString
  if (format) {
    formattedString = htmlString?.replace(/<p><\/p>\n/g, '<br>') || ''
  }
  return parse(formattedString)
}

export function giveMediaTypeBasedOnMimeType(mimeType) {
  if (mimeType.includes('image')) {
    return 'image'
  } else if (mimeType.includes('video')) {
    return 'video'
  } else if (mimeType.includes('audio')) {
    return 'audio'
  } else {
    return 'file'
  }
}

export function getVideoFileMetaData(file) {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src)
      resolve({
        duration: video.duration,
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }

    video.onerror = function () {
      reject('Error loading video file')
    }

    video.src = URL.createObjectURL(file)
  })
}

export function filterValidAndNonRepeatedEmails(emails) {
  return [...new Set(emails || [])].filter((email) => !!email && isValidEmail(email))
}

export function areStringsEqualIgnoreCase(str1, str2) {
  if (str1 === undefined || str1 === null || str2 === undefined || str2 === null) {
    return false
  }
  return str1.toLowerCase() === str2.toLowerCase()
}

export async function loadOptionsForAsyncPaginate(
  search,
  loadedOptions,
  {
    getFunction,
    apiUrl,
    otherParams,
    transformDataToReturn = (data) => data,
    setLoading = () => {},
    ...additional
  },
) {
  setLoading && setLoading(true)
  const resp = await getFunction(apiUrl, {
    page: additional?.page || 0,
    search: search,
    ...otherParams,
  })
  var dataToReturn = transformDataToReturn(resp.data)
  setLoading && setLoading(false)
  const hasMore = resp.data?.length > 0

  return {
    options: dataToReturn,
    hasMore: hasMore,
    additional: {
      page: additional?.page + 1,
      getFunction,
      apiUrl,
      otherParams,
      setLoading,
      transformDataToReturn,
      ...additional,
    },
  }
}

export function prospectContactIsEmpty(prospect) {
  return !prospect?.email && !prospect?.mobile
}

export function workforceContactIsEmpty(workforce) {
  return !workforce?.id && !workforce?.uid && !workforce?.userId
}

export function getPercentage(num, denom) {
  if (!denom) return null
  return parseFloat(((num / denom) * 100).toFixed(2))
}

export function downloadExcelSheetFromRows({ rows, titleOfSheet, workSheetTitle }) {
  const worksheet = XLSX.utils.json_to_sheet(rows)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, workSheetTitle)
  XLSX.writeFile(workbook, `${titleOfSheet}.xlsx`, { compression: true })
}

export function downloadExcelSheetFromSheetJsWbData({ workbookData, titleOfSheet }) {
  XLSX.writeFile(workbookData, `${titleOfSheet}.xlsx`, { compression: true })
}

export function convertAssessmentIdBasedCategoryListToMetaCategoryList(list) {
  const newList = []
  list?.forEach((item) => {
    newList.push(convertAssessmentIdBasedCategoryDataToCategoriesObject(item))
  })
  return newList
}

function convertAssessmentIdBasedCategoryDataToCategoriesObject(data) {
  return {
    apiParam: 'categories',
    id: data?.assessmentId,
    enabled: true,
    deleted: false,
    title: data?.title,
    coverIcon: data?.coverIcon,
    coverImage: data?.coverImage,
    name: data?.title,
    // internalTitle: 'Guided Meditations',
    // singleTitle: 'Guided Meditation',
    categoryType: data?.customFields?.categoryType,
    // player: false,
    // icon: 'ic_stones',
    // appListable: true,
    // orderId: 0,
    // emptyPageScreen: {
    //   title: 'No Courses Added',
    //   subtext: 'When you start a guided meditation course, it would show up here',
    //   btnText: 'Explore Guided Meditations',
    //   images: [
    //     'MEDIA-ca6cae93a8a043f2a0136b1f28fb9c06',
    //     'MEDIA-dd8e571b5a174583ad5082cec276c34d',
    //     'MEDIA-a1517c5fa97949e482511ec40b32668d',
    //   ],
    // },
    // translations: {
    //   en: {
    //     name: 'Guided Meditations',
    //     singleTitle: 'Guided Meditation',
    //     emptyPageTitle: 'No Courses Added',
    //     emptyPageSubtext: 'When you start a guided meditation course, it would show up here',
    //     emptyPageBtnText: 'Explore Guided Meditations',
    //     idField: 'Guided Meditations',
    //   },
    // },
    // featureCategory: false,
    // defaultPlayer: 'full_length_player',
    // playerType: 'regular',
    // endJournal: true,
    // plan: true,
    // tabs: ['meditation', 'sleep'],
    // tab: 'meditation',
    // bgMusic: true,
    // maxMusicVolume: 30,
    // minMusicVolume: 1,
    // maxVoiceVolume: 100,
    // minVoiceVolume: 1,
    // sliderPosition: 80,
    // slides: [
    //   {
    //     fileId: 'MEDIA-846bac5045844ecdaa2646943155218c',
    //     inAt: 0,
    //     outAt: 9999,
    //     primaryColor: '#976f26',
    //     secondaryColor: '#042135',
    //     preselected: false,
    //   },
    //   {
    //     fileId: 'MEDIA-a1fa992a66f049f79eb75025408c4617',
    //     inAt: 10000,
    //     outAt: 19999,
    //     primaryColor: '#5c565b',
    //     secondaryColor: '#06060a',
    //     preselected: false,
    //   },
    // ],
    // continuePlayType: 'back_to_back',
    // audioContinuePlayType: 'result_screen',
    // portraitVideoContinuePlayType: 'result_screen',
    // landscapeVideoContinuePlayType: 'result_screen',
    // loopedContinuePlayType: 'result_screen',
    // itemType: 'category',
  }
}

export function getNumberStringWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function removeKeyFromObject(obj, keyToRemove) {
  const { [keyToRemove]: _, ...rest } = obj
  return rest
}

export function getOrdinalSuffixForSuperScript(number) {
  if (typeof number !== 'number' || number < 1) {
    return ''
  }

  const remainder10 = number % 10
  const remainder100 = number % 100

  if (remainder100 >= 11 && remainder100 <= 13) {
    return 'th'
  }

  switch (remainder10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export function getFirstLetterCapitalizedString(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

export function shouldLogOutBasedOnHttpResponseStatus(statusCode) {
  if (statusCode === 401) {
    return true
  }
  return false
}

export const hasDuplicateStrings = (strings) => {
  console.log({ strings })
  const stringSet = new Set(strings)

  console.log({ stringSet })
  return !(stringSet.size === strings.length)
}

export const isTheDuplicateStringInList = ({ strings, stringToCheck }) => {
  if (!Array.isArray(strings) || typeof stringToCheck !== 'string') {
    // console.error("Invalid input: 'strings' must be an array and 'stringToCheck' must be a string.");
    return false
  }

  if (!hasDuplicateStrings(strings)) {
    console.log('no duplicate strings')
    return false
  }

  // Count occurrences of stringToCheck in the list
  const occurrences = strings.filter((str) => str === stringToCheck).length

  console.log({ stringToCheck, occurrences })

  // Return true if the stringToCheck is duplicated
  return occurrences > 1
}

export function getEventTargetValueFromMuiToggleGroup(enforceValue, e, value) {
  if (enforceValue && value === null) {
    return null
  }
  const eventId = e.target.id
  console.log({ eventId, e })

  let fakeEventVObj = { ...FAKE_EVENT_OBJECT }

  fakeEventVObj.target.id = eventId
  fakeEventVObj.target.value = value

  console.log({ fakeEventVObj })

  return fakeEventVObj
}
export function getOrdinalSuffix(num) {
  if (typeof num !== 'number' || isNaN(num)) return ''

  const suffixes = ['th', 'st', 'nd', 'rd']
  const v = num % 100

  return num + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
}
